*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  html {
    scroll-behavior: smooth;
  }
  
}


body {
  margin: 0;
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide scrollbar with Tailwind CSS utilities */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.hide-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.gradient-border {
  position: relative;
  padding: 1px; /* Create space for the gradient border */
  border-radius: 0.75rem; /* Match the Tailwind rounded-lg class */
  background: linear-gradient(to right, #8a2be2, #ff69b4, #ff4500); /* Gradient colors */
}


.gradient-border .content {
  position: relative;
  border-radius: inherit;
  background: white;
  padding: 1rem; /* Adjust the padding as needed */
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner-border {
  border-top-color: transparent;
  animation: spin 1s linear infinite;
}
